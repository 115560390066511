import Image from 'next/image'
import Link from 'next/link'

import { ReactNode } from 'react'
import { analytics } from '~/lib/segment'
import { SecondaryDummyButton } from '../buttons/DummyButton'
type Props = {
  title: string
  slug: string
  imageUrl?: string
  supertitleLabel?: string
  buttonText?: string
  buttonDetailText?: string
  gradient?: boolean
  children?: ReactNode
}

export default function FeatureCard({
  title,
  slug,
  imageUrl,
  supertitleLabel,
  buttonText,
  buttonDetailText,
  gradient = true,
  children,
}: Props) {
  const rounded = 'rounded-[14px]'

  const onLinkClick = () => {
    analytics.track('FeatureCard Clicked', {
      title,
      url: slug,
    })
  }

  return (
    <div className="h-[220px] w-full select-none md:h-[380px] ">
      <Link href={slug} onClick={onLinkClick}>
        <div className={`relative h-full w-full overflow-hidden ${rounded}`}>
          {children ? (
            <>{children}</>
          ) : (
            <>
              <Image
                className="object-cover"
                src={imageUrl ?? ''}
                fill
                alt={`Preview image for class ${title}`}
                sizes="(max-width: 768px) 100vw, 940px"
                priority
              />
              {gradient && (
                <div className="absolute h-full w-full bg-black opacity-40 md:w-[70%] md:bg-inherit md:bg-gradient-to-l md:from-transparent md:via-black/[.45] md:to-black/[.8] md:opacity-100" />
              )}
            </>
          )}
        </div>
        <div
          className={` ${rounded} absolute -bottom-px -left-px -right-px -top-px border-[2px] border-black-alt`}
        ></div>
        <div className="absolute bottom-0 left-0  flex flex-col gap-1 p-5 md:gap-6 md:p-8">
          {supertitleLabel && (
            <div className={`font-super-new text-lg uppercase text-sea-foam md:text-2xl`}>
              {supertitleLabel}
            </div>
          )}
          <div className="text-md font-bold md:w-[60%] md:text-3xl">{title}</div>
          <div>
            <div className="min-w-[180px] max-w-[260px]">
              <SecondaryDummyButton title={buttonText || 'Learn more'} />
            </div>
            {buttonDetailText && <span className="mt-3 text-xs italic">{buttonDetailText}</span>}
          </div>
        </div>
      </Link>
    </div>
  )
}
