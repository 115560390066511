import { useEffect, useMemo, useState } from 'react'

import { dehydrate } from '@tanstack/react-query'
import { GetServerSideProps } from 'next'
import { useRouter } from 'next/router'
import { SwiperSlide } from 'swiper/react'

import { getSsrRequestHeaders } from '~/lib/api/getSsrRequestHeaders'
import {
  PageConfiguration,
  PageConfigurationCategoryData,
  PageConfigurationPageTypes,
  PageConfigurationPartnerData,
  TClass,
} from '~/lib/api/models'
import { fetchAllClasses } from '~/lib/api/useAllClasses'
import { useClassWatchProgress } from '~/lib/api/useClassWatchProgress'
import { fetchUser } from '~/lib/api/useUser'
import { createQueryClient } from '~/lib/createQueryClient'
import { useAppContext } from '~/lib/state_management/context/appContext'
import { getClassCompletedCount } from '~/lib/utils/classes'

import { ChevronDownIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid'
import FeatureBanner from '~/components/elements/banners/FeatureBanner'
import FeatureCard from '~/components/elements/cards/FeatureCard'
import TClassCard from '~/components/elements/cards/TClassCard'
import DropdownMenu from '~/components/elements/dropdowns/DropdownMenu'
import DropdownMenuItem from '~/components/elements/dropdowns/DropdownMenuItem'
import BasicInput from '~/components/elements/fields/BasicInput'
import { TitleLabelPrimary } from '~/components/elements/labels/TitleLabels'
import BasicSwiper from '~/components/elements/swipers/BasicSwiper'
import { CategorySwiper } from '~/components/elements/swipers/CategorySwiper'
import TClassCardSwiper from '~/components/elements/swipers/TClassCardSwiper'
import Footer from '~/components/nav/footer'
import Navbar from '~/components/nav/navbar'
import HappinessGuaranteeSection from '~/components/sections/HappinessGuaranteeSection'
import LoveUsSection from '~/components/sections/LoveUsSection'
import SubscriptionUpsellSection from '~/components/sections/SubscriptionUpsellSection'
import { fetchPageConfig } from '~/lib/api/usePageConfig'
import { fetchPageConfigs } from '~/lib/api/usePageConfigs'

import PageHead from '~/components/PageHead'
import categorySwiperCardsData from '~/lib/json/category-swiper-cards.json'

// Ignore these 4 'special' tags on the /classes page to avoid "overwhelming" users
// https://linear.app/tinyhood-dev/issue/TIN-971/remove-lifestage-tags-from-browse-classes-page
//
// Ideally these should be set in the DB but since I have a feeling this is going to change
// a few times, just hard-coding it here for now. Revisit as a back-end schema change if this sticks.
const ignoredTags = ['Expecting', 'Baby', 'Toddler', 'Preschool']

type Props = {
  classes: TClass[]
  pageConfig: PageConfiguration
  partnerConfigs: PageConfiguration[]
  userHasAdoptionCoupon: boolean
  adoptionCategoryConfig?: PageConfiguration
}

export default function Classes({
  classes,
  pageConfig,
  partnerConfigs,
  userHasAdoptionCoupon,
  adoptionCategoryConfig,
}: Props) {
  const router = useRouter()
  const { user, isLoggedIn } = useAppContext()

  const { data: allClassesWatchProgress } = useClassWatchProgress({ excludeCompleted: false })
  const { data: classProgressReport } = useClassWatchProgress({ excludeCompleted: true })

  const noFilterTag = 'All Classes'
  const [selectedTag, setSelectedTag] = useState(noFilterTag)
  const [continueWatching, setContinueWatching] = useState<TClass[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [featureBannerData, setFeatureBannerData] = useState<{
    [key: string]: any
  }>({})
  const filteredClasses = useMemo(() => {
    let classList = classes
    if (selectedTag !== noFilterTag) {
      classList = classList.filter((tclass) => tclass.tag_names.includes(selectedTag))
    }
    if (searchValue) {
      classList = classList.filter((tclass) =>
        tclass.title.toLowerCase().includes(searchValue.toLowerCase())
      )
    }
    return classList
  }, [selectedTag, classes, searchValue])

  const sortedTagNames = useMemo(() => {
    const remainingTagNames = pageConfig.class_tags
      .filter((tag) => !ignoredTags.includes(tag.title))
      .sort((a, b) => (a.title > b.title ? 1 : -1))
      .map((tag) => tag.title)
    return remainingTagNames
  }, [pageConfig])

  const featuredTagNames = useMemo(() => {
    const remainingTags = sortedTagNames.filter((tag) => pageConfig.tag_names.includes(tag))
    return remainingTags
  }, [sortedTagNames, pageConfig])

  const nonFeaturedTagNames = useMemo(() => {
    const remainingTags = sortedTagNames.filter((tag) => !pageConfig.tag_names.includes(tag))
    return remainingTags
  }, [sortedTagNames, pageConfig])

  const dropDownMenuLabels = useMemo(() => {
    const result = ['All Classes']
    result.push(...sortedTagNames)
    return result
  }, [sortedTagNames])

  const menuButton = useMemo(() => {
    return (
      <div className="flex flex-row items-center gap-2 text-sm font-bold md:text-xl">
        {selectedTag}{' '}
        <ChevronDownIcon className="mt-px h-6 w-6 text-orange md:h-[32px] md:w-[32px]" />
      </div>
    )
  }, [selectedTag])

  // Update the selected tag based on querystring parameter 'topic'.
  // This lets us maintain selected tag state while navigating browser history
  useEffect(() => {
    const tag = router.query.topic as string

    if (tag && sortedTagNames.includes(tag)) {
      setSelectedTag(tag)
    }
  }, [router.query.topic, sortedTagNames])

  // Load server data to render 'continue watching'
  useEffect(() => {
    if (!isLoggedIn) {
      return
    }
    const inProgressClasses = (classProgressReport || []).map((report) => {
      const tclass = classes.find((t) => t.id == report.class_id)
      return tclass
    })

    const filtered: TClass[] = inProgressClasses.filter((v): v is TClass => v != null)
    setContinueWatching(filtered)
  }, [classes, isLoggedIn, classProgressReport])

  useEffect(() => {
    const configureFeatureBanner = async () => {
      const partner = user?.partner

      if (partner) {
        const partnerConfig = partnerConfigs.find((p: PageConfiguration) => p.slug == partner.slug)
        if (!partnerConfig) return

        const data = partnerConfig.data as PageConfigurationPartnerData
        const classIdList = partnerConfig.class_groups.flatMap((classGroup) => classGroup.class_ids)
        const classCompletedCount = getClassCompletedCount(
          classIdList,
          allClassesWatchProgress || []
        )

        setFeatureBannerData({
          title: data.title,
          slug: `/partner/${partner.slug}`,
          image_url: data.feature_banner.image_url,
          image_alt: data.feature_banner.image_alt,
          logo_url: data.logo,
          logo_alt: `${data.partner} logo`,
          detail_data: data.feature_banner.detail_text,
          completed_count: classCompletedCount,
          num_classes: classIdList.length,
        })
      } else if (userHasAdoptionCoupon && adoptionCategoryConfig) {
        const adoptionCategoryConfigData =
          adoptionCategoryConfig.data as PageConfigurationCategoryData

        const classIdList = adoptionCategoryConfig.class_groups.flatMap(
          (class_group) => class_group.class_ids
        )
        const classCompletedCount = getClassCompletedCount(
          classIdList,
          allClassesWatchProgress || []
        )

        setFeatureBannerData({
          title: adoptionCategoryConfig.data.title,
          slug: '/category/adoption',
          image_url: adoptionCategoryConfigData.feature_banner.image_url,
          image_alt: adoptionCategoryConfigData.feature_banner.image_alt,
          logo_url: '',
          logo_alt: '',
          detail_data: adoptionCategoryConfigData.feature_banner.detail_text,
          completed_count: classCompletedCount,
          num_classes: classIdList.length,
        })
      }
    }
    configureFeatureBanner()
  }, [
    user?.all_classes_subscription?.subscription_detail,
    user?.partner,
    partnerConfigs,
    classes,
    allClassesWatchProgress,
  ])

  function setSelectedTagAndQuery(tagname: string) {
    const path = router.asPath
    const rootPath = path.split('?')[0]

    setSelectedTag(tagname)
    router.push({ pathname: rootPath, query: { topic: tagname } }, undefined, { shallow: true })
  }

  function findTClass(tclass_id: string) {
    return classes.find((c) => c.id == tclass_id)
  }

  function tclassListForTag(tagname: string) {
    return classes.filter((tclass) => tclass.tag_names.includes(tagname))
  }

  return (
    <div className="dark bg-black-alt font-sans-new text-white">
      <PageHead title="Online Parenting Classes" />
      <Navbar />

      {Object.keys(featureBannerData).length > 0 ? (
        <section className="md:pt-26 container pb-6 pt-28">
          <FeatureBanner
            title={featureBannerData.title}
            slug={featureBannerData.slug}
            backgroundImageURL={featureBannerData.image_url}
            backgroundImageAlt={featureBannerData.image_alt}
            logo={featureBannerData.logo_url}
            logoAlt={featureBannerData.logo_alt}
            buttonText={'Continue program'}
          >
            <div className="h-1 w-full bg-white/20">
              <div
                className="h-1 bg-white"
                style={{
                  width: `${
                    (featureBannerData.completed_count / featureBannerData.num_classes) * 100
                  }%`,
                }}
              ></div>
            </div>
            <div className="mt-2 flex text-xs md:text-sm">
              {featureBannerData.detail_data ? `${featureBannerData.detail_data} • ` : ''}
              {featureBannerData.completed_count} of {featureBannerData.num_classes} completed
            </div>
          </FeatureBanner>
        </section>
      ) : (
        <>
          <section className="md:pt-26 container pb-6 pt-28 text-center">
            <TitleLabelPrimary>
              Online classes for the <span className="text-orange">modern</span> parent.
            </TitleLabelPrimary>
          </section>
          <section className="container pb-6">
            <BasicSwiper numVisible={1} autoplay={true}>
              {/* Hardcoded elements at the beginning of the swiper */}
              <SwiperSlide>
                <FeatureCard
                  title="Childbirth: What to Expect, Pain Management, and More"
                  slug="/classes/labor-and-birth"
                  imageUrl="https://tinyhood-assets.s3.amazonaws.com/publicassets/class-images-oct-2022/childbirth4.jpg"
                  supertitleLabel="FEATURED CLASS"
                />
              </SwiperSlide>

              <SwiperSlide>
                <FeatureCard
                  title="The Expecting Collection: From Birth to Baby"
                  slug="/category/expecting"
                  imageUrl="https://tinyhood-assets.s3.amazonaws.com/publicassets/classes/expecting/iStock-669281432.jpg"
                  supertitleLabel="FEATURED COLLECTION"
                />
              </SwiperSlide>

              <SwiperSlide>
                <FeatureCard
                  title="The Ultimate Collection to Prepare for Your Baby's Firsts"
                  slug="https://learn.tinyhood.com/safety1st/"
                  imageUrl="https://tinyhood-assets.s3.amazonaws.com/publicassets/safety1st_carousel.jpg"
                  supertitleLabel="Free Resources"
                />
              </SwiperSlide>

              <SwiperSlide>
                <FeatureCard
                  title="Be prepared for any illness, injury, or emergency"
                  slug="/category/safety"
                  imageUrl="https://tinyhood-assets.s3.amazonaws.com/publicassets/safety/Safety101+v2%402x.png"
                  supertitleLabel="Ultimate safety class"
                />
              </SwiperSlide>

              {/* Featured classes from page config */}
              {pageConfig.class_ids.map((tclass_id) => {
                const tclass = findTClass(tclass_id)
                if (!tclass) return <></>
                return (
                  <SwiperSlide key={tclass.id}>
                    <FeatureCard
                      title={tclass.title}
                      slug={`/classes/${tclass.slug}`}
                      imageUrl={tclass.image_url ?? ''}
                      supertitleLabel="Featured class"
                    />
                  </SwiperSlide>
                )
              })}
            </BasicSwiper>
          </section>
        </>
      )}

      {/* Continue watching section */}
      {continueWatching.length > 0 && (
        <section className="container pb-12 pt-12">
          <TClassCardSwiper
            title="Continue watching"
            tclasslist={continueWatching}
            linkToWatchPage={true}
          />
        </section>
      )}

      {/* Featured categories*/}

      <section className="container pb-6 pt-12">
        <CategorySwiper cards={categorySwiperCardsData.cards} />
      </section>

      {!user?.all_classes_subscription && <SubscriptionUpsellSection />}

      {/* Drop down menu */}
      <div className="container relative z-10 flex items-center justify-between pt-12">
        <BasicInput
          value={searchValue}
          placeholder="Search..."
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          icon={<SearchIcon className="h-5 w-5 text-gray-600" />}
          clearableStateSetter={setSearchValue}
        />
        <DropdownMenu buttonContent={menuButton} listOrientation="right">
          {dropDownMenuLabels.map((tag) => {
            return (
              <DropdownMenuItem
                key={tag}
                label={tag}
                allowCheckmarks={true}
                isChecked={selectedTag == tag}
                callback={() => setSelectedTagAndQuery(tag)}
              />
            )
          })}
        </DropdownMenu>
      </div>

      {/* Show results from page config if filter == 'All Classes' with no search query */}
      {filteredClasses.length === classes.length && (
        <div>
          {/* Featured tags */}
          {featuredTagNames.map((tagname) => {
            const classlist = tclassListForTag(tagname)
            if (classlist.length < 1) return null
            return (
              <section key={tagname} className="container pt-12">
                <TClassCardSwiper title={tagname} tclasslist={classlist} />
              </section>
            )
          })}

          {/* Remaining tags */}
          {nonFeaturedTagNames.map((tagname) => {
            const classlist = tclassListForTag(tagname)
            if (classlist.length < 1) return null
            return (
              <section key={tagname} className="container pt-12">
                <TClassCardSwiper title={tagname} tclasslist={classlist} />
              </section>
            )
          })}
        </div>
      )}

      {/* Show grid of classes if any filters are active and/or search query exists */}
      {filteredClasses.length > 0 && filteredClasses.length !== classes.length && (
        <>
          <div className="container grid grid-cols-1 gap-6 py-8 md:grid-cols-3">
            {filteredClasses.map((tclass) => {
              return <TClassCard key={tclass.id} tclass={tclass} />
            })}
          </div>
        </>
      )}

      {/* Show error if filter and/or search matches no classes */}
      {filteredClasses.length === 0 && (
        <div className="container flex items-center justify-center gap-4 py-24">
          <ExclamationCircleIcon className="h-10 w-10 text-salmon md:h-5 md:w-5" />
          <span className="flex flex-col gap-1">
            <span className="text-xl.2 font-bold md:text-2xl">
              Oops! No class titles match your search. Please try again.
            </span>
            <span className="text-m md:text-l">
              Try specific keywords like &quot;birth&quot; or &quot;toddler&quot;.
            </span>
          </span>
        </div>
      )}

      {/* Why parents love us */}
      {!user?.all_classes_subscription && <LoveUsSection />}

      {/* Happiness guarantee */}
      {!user?.all_classes_subscription && <HappinessGuaranteeSection />}

      <Footer />
    </div>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const queryClient = createQueryClient()

  const unsortedClasses = await fetchAllClasses(queryClient, getSsrRequestHeaders(context))
  const user = await fetchUser(queryClient, getSsrRequestHeaders(context))
  const pageConfig = await fetchPageConfig('classes', queryClient)
  const partnerConfigs = await fetchPageConfigs(PageConfigurationPageTypes.Partner, queryClient)

  // Fetch adoption page config if it will be needed
  const userHasAdoptionCoupon =
    user?.all_classes_subscription?.subscription_detail?.coupon_name === 'b2c-adoption'
  const adoptionCategoryConfig = userHasAdoptionCoupon
    ? await fetchPageConfig('adoption', queryClient)
    : null

  const classes: TClass[] = unsortedClasses.sort((a, b) => (a.title > b.title ? 1 : -1))

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      classes,
      pageConfig,
      partnerConfigs,
      userHasAdoptionCoupon,
      adoptionCategoryConfig,
    },
  }
}
