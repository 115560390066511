import Head from 'next/head'

type Props = {
  title: string
  description?: string | null
}

export default function PageHead({ title, description }: Props) {
  const pageTitle = `${title} | Tinyhood`

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta property="og:title" key="og_title" content={pageTitle} />

      {description && (
        <>
          <meta name="description" key="description" content={description} />
          <meta property="og:description" key="og_description" content={description} />
        </>
      )}
    </Head>
  )
}
