type TitleLabelProps = {
  children: React.ReactNode
  largeFont?: boolean
  heading?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

export function TitleLabelPrimary({ children, largeFont, heading = 'h1' }: TitleLabelProps) {
  const textblock = largeFont ? 'text-xl.3 md:text-5xl' : 'text-2.2xl  md:text-3xl'
  const Heading = heading
  return <Heading className={`${textblock} mb-5 font-sans-new font-bold`}>{children}</Heading>
}

export function TitleLabelSecondary({ children, largeFont }: TitleLabelProps) {
  const textblock = largeFont ? 'text-xl.3 md:text-4xl' : 'text-xl.3 md:text-2.5xl'
  return <h2 className={`${textblock} mb-5 font-sans-new font-bold md:mb-10`}>{children}</h2>
}

export function TitleLabelTertiary({ children }: TitleLabelProps) {
  return <h3 className="mb-7.5 font-sans-new text-xl.2 font-bold md:text-2xl">{children}</h3>
}
